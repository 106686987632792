import axios from 'axios';
import { API } from "../config";
import queryString from 'query-string';

export const authenticate = (data, next) => {
    const JSONData = JSON.stringify(data);
    if (typeof window !== "undefined") {
        localStorage.setItem("jwt", JSONData);
        next();
    }
}

export const signout = (next) => {
    if (typeof window !== "undefined") {
        localStorage.removeItem("jwt");
        next();
        return axios.get(`/api/users/auth/signout`)
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
    }
}

export const isAuthenticated = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem('jwt')) {
        return JSON.parse(localStorage.getItem('jwt'));
    } else {
        return false;
    }
}

export const uploadFiles = (file, postId) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("postId", postId);
    return axios.post("/postimage/create", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

export const list = async params => {
    const query = queryString.stringify(params);
    try {
        return await axios.get(`/api/post/list/search?${query}`);
    } catch (err) {
        console.log(err);
    }
}

export const getMainCategoryList = () => {
    try {
       return axios.get(`/api/maincategory/list`);
    } catch (err) {
        console.log(err);
    }
}
