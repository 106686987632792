import React, { useContext } from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  makeStyles,
  emphasize,
  Typography
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import searchContext from "./Context";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    borderRadius: '10px',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      textDecoration: 'none',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}));

const Breadcrumbs = props => {
  const classes = useStyles();
  const {
    history,
    location: { pathname }
  } = props;
  const pathnames = pathname.split("/").filter(x => x);

  const [data, setData] = useContext(searchContext)
  const { categories, category, search, results, searched } = data;

  return (
    <MUIBreadcrumbs separator="›" aria-label="breadcrumb">
      {pathnames.length > 0 ? (
        <Link className={classes.root} onClick={() => {
          history.push("/")
          setData({ category: '', search: '', results: [], searched: false })
        }}>Home</Link>
      ) : (
        <Typography> Home </Typography>
      )}
      {pathnames.map((name, index) => {
        const name2 = name.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography key={name}>{name2 && name2.replaceAll("-", " ")}</Typography>
        ) : (
          <Link key={name} className={classes.root} onClick={() => {
            history.push(routeTo)
            setData({ ...data, searched: false })
          }}>
            {name2 && name2.replaceAll("-", " ")}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);
