import React, { useState, createContext, lazy, Suspense } from "react";
import { withRouter } from "react-router";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AdminRoute from "./AdminRoute";
import UserRoute from "./UserRoute";
import searchContext from "./Context";
import { useLocalStorage } from "useLocalStorage"; 

const Home = lazy(() => import("../pages"));
const AdminDashboard = lazy(() => import("../pages/Dashboard"));
const AlertsManagement = lazy(() =>
  import("../pages/Dashboard/AlertsManagement")
);
const CategoryManagement = lazy(() =>
  import("../pages/Dashboard/CategoryManagement/CategoryManagement")
);
const ConfigManagement = lazy(() =>
  import("../pages/Dashboard/ConfigManagement")
);
const LanguagesPage = lazy(() =>
  import("../pages/Dashboard/UserManagement/LanguagesPage")
);
const PostManagement = lazy(() => import("../pages/Dashboard/PostManagement"));
const SignInPage = lazy(() => import("../pages/signin"));
const UserManagement = lazy(() =>
  import("../pages/Dashboard/UserManagement/UserManagement")
);
const CountriesPage = lazy(() =>
  import("../pages/Dashboard/UserManagement/CountriesPage")
);
const StatesPage = lazy(() =>
  import("../pages/Dashboard/UserManagement/StatesPage")
);
const CitiesPage = lazy(() =>
  import("../pages/Dashboard/UserManagement/CitiesPage")
);
const CountiesPage = lazy(() =>
  import("../pages/Dashboard/UserManagement/CountiesPage")
);
const UserAccountsPage = lazy(() =>
  import("../pages/Dashboard/UserManagement/UserAccountsPage")
);
const CategoriesPage = lazy(() =>
  import("../pages/Dashboard/CategoryManagement/CategoriesPage")
);
const ScreenControlsPage = lazy(() =>
  import("../pages/Dashboard/CategoryManagement/ScreenControlsPage")
);
const PropertiesPage = lazy(() =>
  import("../pages/Dashboard/CategoryManagement/PropertiesPage")
);
const PostsPage = lazy(() =>
  import("../pages/Dashboard/PostManagement/PostsPage")
);
const PostTypesPage = lazy(() =>
  import("../pages/Dashboard/PostManagement/PostTypesPage")
);
const PriceTypesPage = lazy(() =>
  import("../pages/Dashboard/PostManagement/PriceTypesPage")
);
const ProductDetails = lazy(() => import("pages/UserPages/ProductDetails"));
const CategoryDetails = lazy(() => import("pages/UserPages/CategoryDetails"));
const PostAttributesPage = lazy(() =>
  import("../pages/Dashboard/PostManagement/PostAttributesPage")
);
const PostImagesPage = lazy(() =>
  import("../pages/Dashboard/PostManagement/PostImagesPage")
);
const PostListing = lazy(() => import("../pages/UserPages/PostListing"));
const SignUpPage = lazy(() => import("../pages/SignUp"));
const Terms = lazy(() => import("../pages/terms"));
const HowItWorks = lazy(() => import("../pages/howitworks"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Contact = lazy(() => import("../pages/contact"));
const UserHome = lazy(() => import("./dashboard_user/pages/home/UserHome"));
const User = lazy(() => import("./dashboard_user/pages/user/User"));
const NewPost = lazy(() => import("./dashboard_user/pages/AdPost/NewPost"));
const AdList = lazy(() => import("./dashboard_user/pages/AdPostList/AdList"));
const FavAdList = lazy(() =>
  import("./dashboard_user/pages/AdPostList/FavAdList")
);
const SoldAdList = lazy(() =>
  import("./dashboard_user/pages/AdPostList/SoldAdList")
);
const FeaturedAdList = lazy(() =>
  import("./dashboard_user/pages/AdPostList/FeaturedAdList")
);
const PostImages = lazy(() =>
  import("./dashboard_user/pages/AdPostList/AdImages")
);
const ItemConditionsPage = lazy(() =>
  import("../pages/Dashboard/PostManagement/ItemConditionsPage")
);
const PostingAsTypesPage = lazy(() =>
  import("../pages/Dashboard/PostManagement/PostingAsTypesPage")
);
const PostStatusesPage = lazy(() =>
  import("../pages/Dashboard/PostManagement/PostStatusesPage")
);
const VerifyEmail = lazy(() => import("./main/Signup/VerifyEmail"));
const Messenger = lazy(() => import("../pages/Dashboard/Messaging"));
const ResetPassword = lazy(() => import("./main/Signup/ResetPassword"));
const ForgotPassword = lazy(() => import("./main/Signup/ForgotPassword"));
const RedirectForgotPassword = lazy(() =>
  import("./main/Signup/RedirectForgotPassword")
);
const SuccessfulPasswordReset = lazy(() =>
  import("./main/Signup/SuccessfulPasswordReset")
);
const FeedBack = lazy(() => import("../pages/Dashboard/Messaging/FeedBack"));
const AlertsPage = lazy(() =>
  import("./dashboard_user/pages/Alerts/AlertsPage")
);
const FeedbackPage = lazy(() =>
  import("./dashboard_user/pages/FeedbackReport/FeedbackPage")
);
import FallBackLoader from "components/fallback/index";
import { getAllPostsList } from 'redux/actions/postListActions';
import { getAllCategories } from 'redux/actions/categoriesActions';
import { useDispatch, useSelector } from 'react-redux';
import PrivacyPolicyPage from "pages/PrivacyPolicyPage";
import CookiesPage from "pages/CookiesPage";
import DisclaimerPage from "pages/DisclaimerPage";
import FrequentlyAskedPage from "pages/FrequentlyAskedPage";
import AdvertisePage from "pages/AdvertisePage";
import { getAllPostImagesList } from "redux/actions/postImagesAction";

export const imageContext = createContext(null);
export const redirectContext = createContext(null);
export const messageContext = createContext(null);
export const composeContext = createContext(null);

let postList = {};
let categoryList = [];
let postImagesList = [];

function App() {
  const [redirectStatus, setRedirect] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);

  const dispatch = useDispatch();
  postList = useSelector(state => state.postLists);
  categoryList = useSelector(state => state.categories);
  postImagesList = useSelector(state => state.postImages);
  
  
  React.useEffect(() => {
    // action dispatches
    dispatch(getAllCategories());
    dispatch(getAllPostsList());
    dispatch(getAllPostImagesList());
    //setData({})
    return () => { }; 
  }, []);

  const [data, setData] = useLocalStorage("data", {
    categories: [],
    category: "",
    search: "",
    results: [],
    searched: false,
    loading: false
  });
  console.log(data)
  
  const [filesForUpload, setFilesForUpload] = useState({
    selectedFiles: undefined,
    previewImages: [],
    progressInfos: [],
    message: [],
    imageInfos: [],
  });

  return (
    <searchContext.Provider value={[data, setData]}>
      <imageContext.Provider value={[filesForUpload, setFilesForUpload]}>
        <redirectContext.Provider value={[redirectStatus, setRedirect]}>
          <messageContext.Provider value={[conversations, setConversations]}>
            <composeContext.Provider value={[messages, setMessages]}>
              <>
                <Suspense fallback={<FallBackLoader />}>
                  <Router>
                    <Switch>
                      <Route path="/" component={Home} exact />
                      <Route
                        path="/verifyemail"
                        component={VerifyEmail}
                        exact
                      />
                      <Route
                        path="/redirectforgotpassword"
                        component={RedirectForgotPassword}
                        exact
                      />
                      <Route
                        path="/passwordreset"
                        component={ResetPassword}
                        exact
                      />
                      <Route
                        path="/forgotpassword"
                        component={ForgotPassword}
                        exact
                      />
                      <Route
                        path="/passwordresetsuccess"
                        component={SuccessfulPasswordReset}
                        exact
                      />

                      <AdminRoute
                        path="/dashboard"
                        component={AdminDashboard}
                        exact
                      />

                      <AdminRoute
                        path="/dashboard/usermanagement"
                        component={UserManagement}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/usermanagement/languages"
                        component={LanguagesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/usermanagement/countries"
                        component={CountriesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/usermanagement/states"
                        component={StatesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/usermanagement/cities"
                        component={CitiesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/usermanagement/counties"
                        component={CountiesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/usermanagement/users"
                        component={UserAccountsPage}
                        exact
                      />

                      <AdminRoute
                        path="/dashboard/categorymanagement"
                        component={CategoryManagement}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/categorymanagement/categories"
                        component={CategoriesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/categorymanagement/screencontrols"
                        component={ScreenControlsPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/categorymanagement/properties"
                        component={PropertiesPage}
                        exact
                      />

                      <AdminRoute
                        path="/dashboard/postmanagement"
                        component={PostManagement}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/postmanagement/posts"
                        component={PostsPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/postmanagement/postattributes"
                        component={PostAttributesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/postmanagement/postimages"
                        component={PostImagesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/postmanagement/posttypes"
                        component={PostTypesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/postmanagement/pricetypes"
                        component={PriceTypesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/postmanagement/postconditions"
                        component={ItemConditionsPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/postmanagement/postingtypes"
                        component={PostingAsTypesPage}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/postmanagement/poststatuses"
                        component={PostStatusesPage}
                        exact
                      />

                      <AdminRoute
                        path="/dashboard/alertmanagement"
                        component={AlertsManagement}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/configuration"
                        component={ConfigManagement}
                        exact
                      />
                      <AdminRoute
                        path="/dashboard/feedback"
                        component={FeedBack}
                        exact
                      />

                      <UserRoute path="/user" component={UserHome} exact />
                      <UserRoute
                        path="/user/postlist"
                        exact
                        component={AdList}
                      />
                      <UserRoute path="/user/profile" exact component={User} />
                      <UserRoute
                        path="/user/favoritelist"
                        exact
                        component={FavAdList}
                      />
                      <UserRoute
                        path="/user/soldlist"
                        exact
                        component={SoldAdList}
                      />
                      <UserRoute
                        path="/user/featuredlist"
                        exact
                        component={FeaturedAdList}
                      />
                      <UserRoute
                        path="/user/postad"
                        exact
                        component={NewPost}
                      />
                      <UserRoute
                        path="/user/postimages"
                        component={PostImages}
                        exact
                      />
                      <UserRoute
                        path="/user/messaging"
                        exact
                        component={Messenger}
                      />
                      <UserRoute
                        path="/user/alertList"
                        exact
                        component={AlertsPage}
                      />
                      <UserRoute
                        path="/user/myfeedbackList"
                        exact
                        component={FeedbackPage}
                      />

                      <Route
                        path="/postlisting"
                        component={PostListing}
                        exact
                      />
                      <Route path="/signin" component={SignInPage} exact />
                      <Route path="/register" component={SignUpPage} exact />
                      <Route path="/terms" component={Terms} exact />
                      <Route path="/privacy" component={PrivacyPolicyPage} exact />
                      <Route path="/cookies" component={CookiesPage} exact />
                      <Route path="/disclaimer" component={DisclaimerPage} exact />
                      <Route path="/frequently-asked-questions" component={FrequentlyAskedPage} exact />
                      <Route path="/howitworks" component={HowItWorks} exact />
                      <Route path="/contact" component={Contact} exact />
                      <Route path="/advertise-with-us" component={AdvertisePage} exact />
                      <Route
                        path="/category/:category_name"
                        exact={true}
                        component={CategoryDetails}
                      />
                      <Route
                        path="/category/:category_name/:subCategoryId"
                        exact={true}
                        component={CategoryDetails}
                      />
                      <Route
                        path="/category/:category_name/:categoryName/:postTitle"
                        exact={true}
                        component={ProductDetails}
                      />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </Router>
                </Suspense>
              </>
            </composeContext.Provider>
          </messageContext.Provider>
        </redirectContext.Provider>
      </imageContext.Provider>
    </searchContext.Provider>
  );
}
export {postList};
export {categoryList};
export {postImagesList};
export default withRouter(App);
