import React from "react";
import { animateScroll as scroll } from "react-scroll";
import logo from "../../../assets/logo.png";
import { Button } from "../../ButtonElement";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTwitter,
  FaVoicemail,
  FaYoutube,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialmediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIconLink,
  SocialIcons,
  FooterSubscription,
  FooterSubText,
  Form,
  FormInput,
  FooterSubHeading,
} from "./FooterElements";
import tiktok from "assets/tiktok.png";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const [onlyWidth, setOnlyWidth] = React.useState();

  React.useEffect(() => {
    setOnlyWidth(window.outerWidth);
  }, []);

  return (
    <FooterContainer>
      <FooterWrap>
      {onlyWidth >= 520 ? (
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <div style={{ width: "20rem" }}>
              <FooterSubscription>
                <FooterLinkTitle>What We Do</FooterLinkTitle>
                <FooterSubHeading>
                Trio Marketplace Corporation (TMP) is an Online Classified
                Advertisement website with a sole purpose of bringing a fresh
                look at the current industry standards. Trio Marketplace
                Corporation is a federal incorporation registered under the
                Canada Business Corporations Act (CBCA) with its headquarters
                in Toronto, Ontario.
                </FooterSubHeading>
              </FooterSubscription>
            </div>
            <FooterLinkItems>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterLink to="/howitworks">How It Works</FooterLink>
              <FooterLink to="/terms">Terms of Service</FooterLink>
              <FooterLink to="/privacy">Privacy Policy</FooterLink>
              <FooterLink to="/cookies">Cookie Policy</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLink to="/contact">Contact</FooterLink>
              <FooterLink to= '/advertise-with-us'>Advertise With Us</FooterLink>
              <FooterLink to="/disclaimer">Disclaimer</FooterLink>
              <FooterLink to="/frequently-asked-questions">FAQ</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Quick Links</FooterLinkTitle>
              <FooterLink to="/user/postad">Post an Ad</FooterLink>
              <FooterLink to="/user/myfeedbackList">Submit Feedback</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      ) : (
        <FooterLinksContainer style={{display: 'flex', flexDirection: 'column'}} >
          <FooterLinksWrapper>
            <div style={{ width: "20rem" }}>
              <FooterSubscription>
                <FooterLinkTitle>What We Do</FooterLinkTitle>
                <FooterSubHeading>
                Trio Marketplace Corporation (TMP) is an Online Classified
                Advertisement website with a sole purpose of bringing a fresh
                look at the current industry standards. Trio Marketplace
                Corporation is a federal incorporation registered under the
                Canada Business Corporations Act (CBCA) with its headquarters
                in Toronto, Ontario.
                </FooterSubHeading>
              </FooterSubscription>
            </div>
            <FooterLinkItems>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterLink to="/howitworks">How It Works</FooterLink>
              <FooterLink to="/terms">Terms of Service</FooterLink>
              <FooterLink to="/privacy">Privacy Policy</FooterLink>
              <FooterLink to="/cookies">Cookie Policy</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLink to="/contact">Contact</FooterLink>
              <FooterLink to= '/advertise-with-us'>Advertise With Us</FooterLink>
              <FooterLink to="/disclaimer">Disclaimer</FooterLink>
              <FooterLink to="/frequently-asked-questions">FAQ</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Quick Links</FooterLinkTitle>
              <FooterLink to="/user/postad">Post an Ad</FooterLink>
              <FooterLink to="/user/myfeedbackList">Submit Feedback</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      )}


        <SocialMedia>
          <SocialmediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <img src={logo} width="45px" height="45px" alt="logo" />
            </SocialLogo>
            <WebsiteRights>
              Copyright © {new Date().getFullYear} All Rights Reserved.
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.facebook.com/Triomarketplace"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.instagram.com/triomarketplace/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="https://twitter.com/MarketplaceTrio"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.youtube.com/channel/UCkss9bFS7Wgi3m9yZvTtk1w"
                target="_blank"
                aria-label="YouTube"
              >
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.pinterest.ca/triomarketplace/"
                target="_blank"
                aria-label="Pinterest"
              >
                <FaPinterest />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.tiktok.com/@triomarketplace"
                target="_blank"
                aria-label="Tiktok"
              >
                <img src={tiktok} width="40" height="40" />
              </SocialIconLink>
            </SocialIcons>
          </SocialmediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
