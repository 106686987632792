import React from 'react'
import { Link } from 'react-router-dom'
import {
    Grid, Card, CardMedia,CardContent,CardActions,IconButton,
    Typography
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { RWebShare } from "react-web-share";
import TimeAgo from "react-timeago";
import Button2 from "@mui/material/Button";
import { postImagesList } from 'components/App';
import NewCard from 'components/New Card/NewCard';



function ResultCard({f,onClick}) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    });
    const getImageArray = (postId)=>{
      let arr = postImagesList.allPostsImageList&& postImagesList.allPostsImageList.rows.filter(x=>x.postId===postId)
      return arr
    }
    let imgData = getImageArray(f.postId)

    return (
        <Grid  item xs={12} sm={6} md={4} onClick= {onClick}>
              <Link
                to={{
                  pathname: `/category/${f.parentCategoryName
                    .replace(/\W+/g, "-")
                    .toLowerCase()}/${f.categoryName
                    .replace(/\W+/g, "-")
                    .toLowerCase()}/${f.postTitle
                    .replace(/\W+/g, "-")
                    .toLowerCase()}`,
                  state: {
                    data: f,
                  },
                }}
              >
                <NewCard
                  imgsrc={`data:${(imgData && imgData[0]) && imgData[0].imageType
                  };base64, ${(imgData && imgData[0]) && imgData[0].imageData
                  }`}
                  postTitle={`${f.postTitle.slice(0, 15)} ... `}
                  postDetail={`${f.postDetail.slice(0, 50)} ... `}
                  time= {f.createdAt}
                  price= {f.expectedPrice===0? 'Please Contact':`${formatter.format(f.expectedPrice)}`}
                  postCategory= {f.parentCategoryName}
                  featured= {f.isFeatured}
                  data={{
                    text: "Checkout this Product from TrioMarketPlace!",
                    url: `https://triomarketplace.ca/category/${f.parentCategoryName
                      .replace(/\W+/g, "-")
                      .toLowerCase()}/${f.categoryName
                        .replace(/\W+/g, "-")
                        .toLowerCase()}/${f.postTitle
                          .replace(/\W+/g, "-")
                          .toLowerCase()}`,
                    title: "Checkout this Product",
                  }}
                 />
              </Link>
            </Grid>
    )
}

export default ResultCard;