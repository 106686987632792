import React, { useState, useEffect, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import {
  CategoryWrapper,
  CategoryChildContainer,
  FilterConfigurationContainer,
  CategoryContainer,
} from "./CategorySectionElements";
import api from "../../../utils/Api";
import CheckBox from "pages/UserPages/components/CheckBox";
import RadioBox from "pages/UserPages/components/RadioBox";
import { prices } from "pages/UserPages/components/FixedPrices";
import searchContext from "components/Context";
import SearchResults from "pages/UserPages/components/SearchResults";
import { useLocalStorage } from "useLocalStorage";
import Logo from "../../../assets/logo.png";
import ScrollToTop from "components/ScrollToTop";
import PrivacyPolicy from "components/main/CategorySection/PrivacyPolicy";

const locations = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon Territory",
];

const CategorySection = () => {
  const [mainCategories, setMainCategories] = useLocalStorage(
    "mainCategories",
    []
  );

  const mainCategoryList = async () => {
    let response = await api.getParentCategoryWithSubCategoryList();
    if (response) {
      setMainCategories(response.data.data);
    }
  };
  React.useEffect(() => {
    mainCategoryList();
  }, []);
  const [myFilters, setMyFilters] = useState({
    filters: { mainCategory: [], price: [] },
  });
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = React.useState(true);

  const [data, setData] = useContext(searchContext);
  const { categories, category, search, results, searched } = data;

  /**Make sure if a category begins with the word Other, make it last in the listing */
  const reOrganizeSubCategories = (data) => {
    for (let item of data) {
      let others = [];
      let list = [];
      for (let sub of item.subCategoryList) {
        if (sub.subCategoryName.startsWith("Other")) {
          others.push(sub);
          continue;
        }
        list.push(sub);
      }
      if (others.length > 0) {
        others.forEach((other) => {
          list.push(other);
        });
      }
      item.subCategoryList = list;
    }
    return data;
  };
  const fetchCategoryData = async () => {
    let response = await api.getParentCategoryWithSubCategoryList();
    if (response) {
      setCategoryData(reOrganizeSubCategories(response.data.data));
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCategoryData();
  }, []);

  const handleFilters = (filters, filterBy) => {
    const newFilters = { ...myFilters };
    newFilters.filters[filterBy] = filters;
    if (filterBy === "price") {
      let priceValues = handlePrice(filters);
      newFilters.filters[filterBy] = priceValues;
    }
    loadFilteredResults(myFilters.filters);
    setMyFilters(newFilters);
  };

  const handlePrice = (value) => {
    const data = prices;
    let array = [];
    for (let key in data) {
      if (data[key].id === parseInt(value)) {
        array = data[key].array;
      }
    }
    return array;
  };

  const loadFilteredResults = (newFilters) => {
    //console.log(newFilters);
  };

  const split_const = Math.ceil(categoryData && categoryData.length / 3);

  const [onlyWidth, setOnlyWidth] = React.useState();

  React.useEffect(() => {
    setOnlyWidth(window.outerWidth);
  }, []);

  return (
    <CategoryContainer>
      {onlyWidth >= 960 ? (
        <CategoryWrapper className="w3-row-padding">
          <FilterConfigurationContainer>
            <h5>Filter by Location</h5>
            <select className="w3-input w3-border w3-round">
              {locations.map((f, key) => (
                <option key={key}>{f}</option>
              ))}
            </select>
            <hr />
            <h5>Filter by Categories</h5>
            <hr />
            <ul>
              <CheckBox
                mainCategories={mainCategories}
                handleFilters={(filters) =>
                  handleFilters(filters, "mainCategory")
                }
              />
            </ul>
            <hr />
            <h5>Filter by Price range</h5>
            <hr />
            <div>
              <RadioBox
                prices={prices}
                handleFilters={(filters) => handleFilters(filters, "price")}
              />
            </div>
          </FilterConfigurationContainer>
          <ScrollToTop />
          <div style={{ width: "75%", fontFamily: "Segoe UI" }} className="w3-quarter">
            <PrivacyPolicy />
          </div>
        </CategoryWrapper>
      ) : (
        <CategoryWrapper className="w3-row-padding">
          <div
            style={{ width: "75%", marginLeft: "12.5%" }}
            className="w3-quarter"
          >
            <PrivacyPolicy />
          </div>

          <FilterConfigurationContainer>
            <h5>Filter by Location</h5>
            <select className="w3-input w3-border w3-round">
              {locations.map((f, key) => (
                <option key={key}>{f}</option>
              ))}
            </select>
            <hr />
            <h5>Filter by Categories</h5>
            <hr />
            <ul>
              <CheckBox
                mainCategories={mainCategories}
                handleFilters={(filters) =>
                  handleFilters(filters, "mainCategory")
                }
              />
            </ul>
            <hr />
            <h5>Filter by Price range</h5>
            <hr />
            <div>
              <RadioBox
                prices={prices}
                handleFilters={(filters) => handleFilters(filters, "price")}
              />
            </div>
          </FilterConfigurationContainer>
        </CategoryWrapper>
      )}
    </CategoryContainer>
  );
};

export default CategorySection;
