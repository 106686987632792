import axios from 'axios';

const instance = axios.create({
    baseURL: '/api',
    headers: {
        'content-type': 'application/json',
    },
});

export default {
    getPropertyListByCategoryId: (id) => instance({
        'method': 'GET',
        'url': `/categoryproperty/${id}`
    }),

    getUserRoleList: () => instance({
        'method': 'GET',
        'url': '/userrole/list'
    }),

    getUserById: (userAccountId) => instance({
        'method': 'GET',
        'url': `/useraccount/${userAccountId}`
    }),

    getUserList: () => instance({
        'method': 'GET',
        'url': '/useraccount/list'
    }),

    getUserListByRole: (roleName) => instance({
        'method': 'GET',
        'url': `/useraccount/list/${roleName}`
    }),

    getPostList: () => instance({
        'method': 'GET',
        'url': '/post/list/allPosts'
    }),

    getPostImagesLists: () => instance({
        'method': 'GET',
        'url': '/post/image/list/allPostImages'
    }),

    getAttributesByPostId: (postId) => instance({
        'method': 'GET',
        'url': `/postattribute/${postId}`
    }),

    getPostListByUserId: (userAccountId) => instance({
        'method': 'GET',
        'url': `/post/user/${userAccountId}`
    }),

    getFeaturedPostListByUserId: (userAccountId) => instance({
        'method': 'GET',
        'url': `/post/user/featured/${userAccountId}`
    }), 

    getExpiredPostListByUserId: (userAccountId) => instance({
        'method': 'GET',
        'url': `/post/user/expired/${userAccountId}`
    }),

    getPostAlertList: () => instance({
        'method': 'GET',
        'url': `/postalert/list`
    }),

    getPostAlertListByUserId: (userAccountId) => instance({
        'method': 'GET',
        'url': `/postalert/list/${userAccountId}`
    }),

    getFavoritePostList: () => instance({
        'method': 'GET',
        'url': `/favoritepost/list`
    }),

    getFavoritePostListByUserId: (userAccountId) => instance({
        'method': 'GET',
        'url': `/favoritepost/list/${userAccountId}`
    }),

    getPostFeedbackList: () => instance({
        'method': 'GET',
        'url': `/postfeedback/list`
    }),

    getPostFeedbackListByUserId: (userAccountId) => instance({
        'method': 'GET',
        'url': `/postfeedback/list/${userAccountId}`
    }),

    getPostCategoryList: () => instance({
        'method': 'GET',
        'url': '/category/list'
    }),

    getMainPostCategoryList: () => instance({
        'method': 'GET',
        'url': '/maincategory/list'
    }),

    getSubPostCategoryList: () => instance({
        'method': 'GET',
        'url': '/allsubcategory/list'
    }),

    getPostedCategoryList: () => instance({
        'method': 'GET',
        'url': '/post/category/list'
    }),

    getPostingUserList: () => instance({
        'method': 'GET',
        'url': '/post/user/list'
    }),

    getParentCategoryWithSubCategoryList: () => instance({
        'method': 'GET',
        'url': '/subcategory/list'
    }),

    getMainCategoryIdByCategoryId: (id) => instance({
        'method': 'GET',
        'url': `/maincategory/${id}`
    }),

    getCountryList: () => instance({
        'method': 'GET',
        'url': '/country/list'
    }),

    getStateList: () => instance({
        'method': 'GET',
        'url': '/state/list'
    }),

    getCityList: () => instance({
        'method': 'GET',
        'url': '/city/list'
    }),

    getCountyList: () => instance({
        'method': 'GET',
        'url': '/county/list'
    }),

    getLanguageList: () => instance({
        'method': 'GET',
        'url': '/language/list'
    }),

    getScreenControlList: () => instance({
        'method': 'GET',
        'url': '/screencontrol/list'
    }),

    getCategoryPropertyList: () => instance({
        'method': 'GET',
        'url': '/property/list'
    }),

    getPostAttributeList: (id) => instance({
        'method': 'GET',
        'url': `/postattribute/${id}`
    }),

    getPostImageList: (id) => instance({
        'method': 'GET',
        'url': `/postimage/${id}`
    }),

    getMainCategoryPosts: (id) => instance({
        method: 'GET',
        url: `/maincategoryposts/${id}`
    }),

    getSubCategoryPosts: (id) => instance({
        method: 'GET',
        url: `/categoryposts/${id}`
    }),

    getPostTypeList: () => instance({
        'method': 'GET',
        'url': '/posttype/list'
    }),

    getPriceTypeList: () => instance({
        'method': 'GET',
        'url': '/pricetype/list'
    }),

    getItemConditionList: () => instance({
        'method': 'GET',
        'url': '/itemcondition/list'
    }),

    getPostingAsTypeList: () => instance({
        'method': 'GET',
        'url': '/postingastype/list'
    }),

    getPostStatusList: () => instance({
        'method': 'GET',
        'url': '/poststatus/list'
    }),

    getCurrencyList: () => instance({
        'method': 'GET',
        'url': '/currency/list'
    }),

    getPostImagesList: () => instance({
        'method': 'GET',
        'url': '/postimage/list'
    }),

    getAllPostImagesByUserId: (id) => instance({
        'method': 'GET',
        'url': `/postimage/list/${id}`
    })

}
