import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import SimpleReactLightbox from "simple-react-lightbox";
import { BrowserRouter } from "react-router-dom";
import "react-lightbox-pack/dist/index.css";
import "./App.css";
import "style/w3.css";
import "style/styles.css";
import { Provider } from "react-redux";
import store from './redux/store/configureStore.js'
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import FallBackLoader from "components/fallback/index";

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={<FallBackLoader />} persistor={persistor}>
          <SimpleReactLightbox>
            <App />
          </SimpleReactLightbox>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
  ,
  document.getElementById("root")
);
