import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../utils/Service';

const UserRoute = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={props => isAuthenticated() && isAuthenticated().userAccount.userRoleName !== 'Admin' ? (
        <Component {...props} />
    ) : (
        <Redirect
            to={{ pathname: '/signin', state: { from: props.location } }}
        />
    )} />
}

export default UserRoute;