import React from 'react'

function Disclaimer() {
  return (
    <div className="w3-row w3-padding-16">
        <div className="w3-container">
            <h1>Disclaimer</h1>
            <p>Updated at 2022-04-22</p>
            <p>
            Trio Marketplace hereby grants you access to https://triomarketplace.ca/ (“the Website”) and invites you to purchase the
            services offered here.
            </p>

            <h3>Definitions and key terms</h3>
            <p>
            To help explain things as clearly as possible in this Disclaimer, every time any of these terms are referenced, are strictly defined
            as:
            </p>
            <ul>
                <li> 
                    <p>
                    <strong>Cookie:</strong>
                    small amount of data generated by a website and saved by your web browser. It is used to identify your browser,
                    provide analytics, remember information about you such as your language preference or login information.
                    </p>
                </li>
                <li>
                    <p>
                    <strong>Company:</strong>
                    when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Trio Marketplace Corp, Toronto, Ontario
                    that is responsible for your information under this Disclaimer.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Service:</strong>refers to the service provided by Trio Marketplace as described in the relative terms (if available) and on this
                        platform.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Website:</strong>
                        Trio Marketplace."'s" site, which can be accessed via this
                        <a
                        href="https://triomarketplace.ca/"
                        rel="external nofollow noopener"
                        target="_blank"
                        >
                        https://triomarketplace.ca/
                      </a>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>You:</strong>a person or entity that is registered 
                        with Trio Marketplace to use the Services.
                    </p>
                </li>
            </ul>

            <h3>Limited liability</h3>
            <p>
            Trio Marketplace endeavours to update and/or supplement the content of the website on a regular basis. Despite our care and
            attention, content may be incomplete and/or incorrect.
            </p>
            <p>
            The materials offered on the website are offered without any form of guarantee or claim to their correctness. These materials
            can be changed at any time without prior notice from Trio Marketplace.
            </p>
            <p>
            Particularly, all prices on the website are stated subject to typing and programming errors. No liability is assumed for the
            implications of such errors. No agreement is concluded on the basis of such errors.
            </p>
            <p>
            Trio Marketplace shall not bear any liability for hyperlinks to websites or services of third parties included on the website. From
            our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality
            links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites
            do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and
            may occur before we have the opportunity to remove a link which may have gone 'bad'.
            </p>
            <p>
            Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are
            beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before
            engaging in any business or uploading any information.
            </p>

            <h3>Errors and Omissions Disclaimer</h3>
            <p>Trio Marketplace is not responsible for any content, code or any other imprecision.</p>
            <p>
            Trio Marketplace does not provide warranties or guarantees.
            </p>
            <p>
            In no event shall Trio Marketplace be liable for any special, direct, indirect, consequential, or incidental damages or any
            damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of
            the Service or the contents of the Service. Trio Marketplace reserves the right to make additions, deletions, or modifications to
            the contents on the Service at any time without prior notice.
            </p>

            <h3>General Disclaimer</h3>
            <p>
            The Trio Marketplace Service and its contents are provided "as is" and "as available" without any warranty or representations of
            any kind, whether express or implied. Trio Marketplace is a distributor and not a publisher of the content supplied by third
            parties; as such, Trio Marketplace exercises no editorial control over such content and makes no warranty or representation as
            to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the
            Trio Marketplace Service. Without limiting the foregoing, Trio Marketplace specifically disclaims all warranties and
            representations in any content transmitted on or in connection with the Trio Marketplace Service or on sites that may appear as
            links on the Trio Marketplace Service, or in the products provided as a part of, or otherwise in connection with, the Trio
            Marketplace Service, including without limitation any warranties of merchantability, fitness for a particular purpose or
            non-infringement of third party rights. No oral advice or written information given by Trio Marketplace or any of its affiliates,
            employees, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change
            without notice. Without limiting the foregoing, Trio Marketplace does not warrant that the Trio Marketplace Service will be
            uninterrupted, uncorrupted, timely, or error-free.
            </p>

            <h3>Affiliate Links Disclosure</h3>
            <p>
            Trio Marketplace has affiliate links and in this section of the Disclaimer we will address how we use those affiliate links from
            other websites/companies and products. These “affiliate links” are specific URLs that contain the affiliate's ID or username.
            </p>
            <p>
            In compliance with the FTC guidelines, please assume the following about links and posts on this site:
            </p>
            <ul>
                <li>
                    <p>
                    Any/all of the links on Trio Marketplace are affiliate links of which we receive a small commission from sales of certain
                    items, but the price is the same for you. As Trio Marketplace has grown, so have costs associated with running and
                    maintaining it, and affiliate links are a way we help offset these costs.
                    </p>
                </li>
                <li>
                    <p>
                    If we post an affiliate link to a product, it is something that we personally use, support and would recommend without an
                    affiliate link.
                    </p>
                </li>
                <li>
                    <p>
                    Unless otherwise noted, all reviews are of items we have purchased and we are not paid or compensated in any way.
                    </p>
                </li>
            </ul>

            <h3>Educational Disclosure</h3>
            <p>
            Any Information provided by Trio Marketplace is for educational purposes only, and is not to be interpreted as a
            recommendation for a specific treatment plan, product, or course of action. Trio Marketplace is a distributor and not a publisher
            of the content supplied by third parties; as such, Trio Marketplace exercises no editorial control over such content and makes no
            warranty or representation as to the accuracy, reliability or currency of any information or educational content provided through
            or accessible via Trio Marketplace. Without limiting the foregoing, Trio Marketplace specifically disclaims all warranties and
            representations in any content transmitted on or in connection with Trio Marketplace or on sites that may appear as links on Trio
            Marketplace, or in the products provided as a part of, or otherwise in connection with, the Trio Marketplace. No oral advice or
            written information given by Trio Marketplace or any of its affiliates, employees, officers, directors, agents, or the like will create
            a warranty.
            </p>

            <h3>Advertising Disclosure</h3>
            <p>
            This website may contain third party advertisements and links to third party sites. Trio Marketplace does not make any
            representation as to the accuracy or suitability of any of the information contained in those advertisements or sites and does not
            accept any responsibility or liability for the conduct or content of those advertisements and sites and the offerings made by the
            third parties.
            </p>
            <p>
            Advertising keeps Trio Marketplace and many of the websites and services you use free of charge. We work hard to make sure
            that ads are safe, unobtrusive, and as relevant as possible.
            </p>
            <p>
            Third party advertisements and links to other sites where goods or services are advertised are not endorsements or
            recommendations by Trio Marketplace of the third party sites, goods or services. Trio Marketplace takes no responsibility for the
            content of any of the ads, promises made, or the quality/reliability of the products or services offered in all advertisements.
            </p>

            <h3>Your Consent</h3>
            <p>
            We've updated our Disclaimer to provide you with complete transparency into what is being set when you visit our site and how
            it's being used. By using our website, registering an account, or making a purchase, you hereby consent to our Disclaimer and
            agree to its terms.
            </p>

            <h3>Changes To Our Disclaimer</h3>
            <p>
            Should we update, amend or make any changes to this document so that they accurately reflect our Service and policies.
            Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use the Service, you
            will be bound by the updated Disclaimer. If you do not want to agree to this or any updated Disclaimer, you can delete your
            account.
            </p>

            <h3>Contact Us</h3>
            <p>
            Don't hesitate to contact us if you have any questions regarding this Disclaimer.
            </p>
            <ul>
                <li>
                <p>Via Email: triomarketplace@gmail.com</p>
                </li>
                <li>
                <p>Via Phone number: +1 (800) 203-9305</p>
                </li>
                <li>
                    <p>Via this Link:
                    <a
                    href="https://triomarketplace.ca/"
                    rel="external nofollow noopener"
                    target="_blank"
                    >
                        https://triomarketplace.ca/
                      </a>
                    </p>
                </li>
            </ul>
            <form id="Login" action="/">
                <button
                type="submit"
                style={{ padding: "16px", borderRadius: "15px" }}
                >
                BACK TO HOMEPAGE
                </button>
            </form>
        </div>
        <div className="w3-quarter">.</div>
    </div>
  )
}

export default Disclaimer