import React, { useState } from "react";
import Footer from "../components/main/Footer/index";
import "../components/main/Signup/login.css";
import HeaderSection from "../components/main/Header/index";
import styles from '../style/Contact.module.css'
import cx from 'classnames'
import Notification from '../components/form_controls/Notification';
import axios from 'axios';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { categoryList } from 'components/App';
import TextField from "@material-ui/core/TextField";
import ScrollToTop from "components/ScrollToTop";

const AdvertisePage = () => {
  const [input, setInput] = useState('')
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });


  const [data, setData] = React.useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phone: ""
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (data.email.includes(".com") && data.email.includes("@") && data.name.length > 1 && data.message.length > 1 && data.subject.length > 1) {
      let data2 = JSON.stringify(data)
      let res = await axios.post(`/api/users/contact`, data2, { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } });
      if (!res.data.success) {
        setNotify({
          isOpen: true,
          message: `${res.data.data}`,
          type: 'error',
        });
      } else {
        setNotify({
          isOpen: true,
          message: 'Received! Will get back to you soon',
          type: 'success',
        });
      }

    }
  }

  const handleChange = (e) => {
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
  }


  return (
    <>
      <ScrollToTop />
      <HeaderSection />
      <div className="w3-row w3-padding-64">
        <div className="w3-quarter">.</div>
        <div className="w3-container">
          <div className="panel">
            <section className={cx(styles.contactForm, styles.ptb100)}>
              <div className={styles.container}>
                <div className={styles.row}>
                  <div className={cx(styles.colmd6, styles.wow, styles.fadeInLeft, styles.animated)} style={{ visibility: 'visible' }}>
                    <div className={styles.sectionHeading}>
                      <h3 className={styles.blogCommentHeading}>Advertise With Us</h3>
                      <p className={styles.headingDes}>
                        Whether you're an SME looking to advertise and grow your business, a car dealer looking to meet in-market buyers
                        or a brand wanting to run dynamic data-driven display campaigns to connect with audiences across the Canada, there
                        are multiple ways to grow your business on TrioMarketPlace.
                      </p>
                      <p className={styles.headingDes}>
                        With our vast and active audience, TrioMarketPlace can connect you to your ideal target audience. 
                        TrioMarketPlace connects your business to people looking for your
                        goods and services simply and cost-effectively.
                      </p>
                    </div>
                    <ul className={styles.contactDetail}>
                      <li><i className="fa fa-phone" aria-hidden="true" /> <a href="tel:+18002039305">+1 (800) 203-9305</a></li>
                      <li><i className="fa fa-envelope" aria-hidden="true" /> <a href="mailto:triomarketplace@gmail.com">triomarketplace@gmail.com</a></li>
                      <li><i className="fa fa-map-marker" aria-hidden="true" /> <span>35 Thorncliffe Park Dr M4H 1J3 Toronto Ontario - Canada.</span></li>
                    </ul>
                  </div>
                  <div className={cx(styles.colmd6, styles.wow, styles.fadeInRight, styles.animated)} style={{ visibility: 'visible' }}>
                    <h3 className={styles.blogCommentHeading}>Contact Us</h3>
                    <p>
                      Interested in finding out more? Complete the form below or call us on +1 (800) 203-9305.
                    </p>
                    <form>
                      <div className={styles.row}>
                        <div className={styles.colmd6}>
                          <div className={styles.formGroup}>
                            <input type="text" id="firstname" onChange={(e) => handleChange(e)} className={styles.formControl} placeholder="First name*" required />
                          </div>
                        </div>
                        <div className={styles.colmd6}>
                          <div className={styles.formGroup}>
                            <input type="text" id="lastname" onChange={(e) => handleChange(e)} className={styles.formControl} placeholder="Last name*" required />
                          </div>
                        </div>
                        <div className={styles.colmd6}>
                          <div className={styles.formGroup}>
                            <input type="text" id="businessname" onChange={(e) => handleChange(e)} className={styles.formControl} placeholder="Business name*" required />
                          </div>
                        </div>
                        <div className={styles.colmd6}>
                          <div className={styles.formGroup}>
                            <input type="text" id="jobtitle" onChange={(e) => handleChange(e)} className={styles.formControl} placeholder="Job Title*" required />
                          </div>
                        </div>
                        <div className={styles.colmd6}>
                          <div className={styles.formGroup}>
                            <input type="text" id="email" onChange={(e) => handleChange(e)} className={styles.formControl} placeholder="Email*" required />
                          </div>
                        </div>
                        <div className={styles.colmd6}>
                          <div className={styles.formGroup}>
                            <input type="text" id="website" onChange={(e) => handleChange(e)} className={styles.formControl} placeholder="Website URL" />
                          </div>
                        </div>
                        <div className={styles.colmd6}>
                          <div className={styles.formGroup}>
                            <input type="text" id="phone" onChange={(e) => handleChange(e)} className={styles.formControl} placeholder="Phone" required />
                          </div>
                        </div>
                        <div className={styles.colmd12}>
                          <span>Which section of our site are you interested in advertising in?</span>
                          <div className={styles.formGroup}>
                            <Autocomplete
                              freeSolo
                              id="category"
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  setData({ ...data, category: newValue.category_name, searched: false });
                                }
                              }}
                              style={{ minWidth: '5.5rem' }}
                              disableClearable
                              options={categoryList.categories}
                              getOptionLabel={option => option.category_name || ""}
                              inputValue={input}
                              onInputChange={(e, newValue) => {
                                setInput(newValue)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Category"
                                  margin="normal"
                                  variant="outlined"
                                  InputProps={{ ...params.InputProps, type: "search" }}
                                  style={{ minWidth: '5.5rem' }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className={styles.colmd12}>
                          <button onClick={(e) => handleSubmit(e)} className={styles.btn}>submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <form id="Login" action="/">
            <button
              type="submit"
              style={{ padding: "16px", borderRadius: "15px" }}
            >
              BACK TO HOMEPAGE
            </button>
          </form>
        </div>
        <div className="w3-quarter">.</div>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
      <Footer />
    </>
  );
};

export default AdvertisePage;
