import React from "react";
import Footer from "../components/main/Footer/index";
import "../components/main/Signup/login.css";
import HeaderSection from "../components/main/Header/index";
import CategorySection from "../components/main/CategorySection/Privacy";
import ScrollToTop from "components/ScrollToTop";

const PrivacyPolicyPage = () => {
  return (
    <>
      <ScrollToTop />
      <HeaderSection />
      <CategorySection />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;