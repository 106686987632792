import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CategoryContainer = styled.div`
    background: #ffffff;
`; 

export const CategoryWrapper = styled.div`
    z-index: 1;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
`;

const CategoryH5 = styled.h5`
    font-size: 1.3rem;
    color: #f15a25;
    font-weight: 700;

    &:hover {
        color: #016abb;
        transition: 0.3s ease-out;
        cursor: pointer;
    }
`;

const CategoryH6 = styled.h6`
    &:hover {
        color: #f15a25;
        transition: 0.3s ease-out;
        cursor: pointer;
    }
`;

export const FilterConfigurationContainer = ({ children }) => <div className='w3-quarter w3-padding'>{children}</div>;

export const CategoryChildContainer = ({ data }) => {
    return (
        <div className='w3-quarter w3-padding'>
            {typeof data !== 'undefined' &&
                Object.keys(data).map((f, index) => { 
                    return (
                        <>
                            <Link
                                to={{
                                    pathname: `/category/${data[f].category_name.replace(/\W+/g, '-').toLowerCase()}`,
                                    state: {
                                        data: data[f],
                                    },
                                }} 
                            >
                                <CategoryH5>{data[f].category_name}</CategoryH5>
                            </Link>
                            {Object.keys(data[f].subCategoryList).map((y, i) => (
                                <Link
                                    to={{
                                        pathname: `/category/${data[f].category_name.replace(/\W+/g, '-').toLowerCase()}/${data[f].subCategoryList[y].subCategoryName.replace(/\W+/g, '-').toLowerCase()}`,
                                        state: {
                                            data: data[f],
                                            subCategoryId: data[f].subCategoryList[y].subCategoryId,
                                        },
                                    }}
                                >
                                    <CategoryH6 key={i}>{data[f].subCategoryList[y].subCategoryName}</CategoryH6>
                                </Link>
                            ))}
                            <br />
                        </>
                    );
                })}
        </div> 
    );
};
