import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { Link as LinkRouter } from 'react-router-dom';

export const LoginModalContainer = styled.div` 
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
`;

export const LoginModalWrapper = styled.div`
    width: 800px;
    height: 500px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 10;
    border-radius: 10px;
`;

export const LoginModalImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    background: #ccc;
`;

export const LoginModalContent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    line-height: 1.8;
    color: #141414;
`;

export const LoginModalP = styled.p`
    margin-bottom: 1rem;
`;

export const LoginModalCloseButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
    color: #141414;
`;

export const ModalFormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;

    @media screen and (max-width: 400px) {
        height: 80%;
    }
`;

export const ModalFormContent = styled.div`
    height: 100%;
    
    @media screen and (max-width: 480px) {
        padding: 10px;
    }
`;

export const ModalForm = styled.form`
    background: #010101;
    max-width: 400px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px 32px;
    padding-bottom:100px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.9);

    @media screen and (max-width: 400px) {
        padding: 32px 32px;
    }
`;

export const ModalFormH1 = styled.h1`
    margin-bottom: 10px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`;

export const ModalFormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 14px;
    color: #fff;
    text-align: left;
`;

export const ModalFormInput = styled.input`
    padding: 10px 10px;
    margin-bottom: 12px;
    border-radius: 4px;
    border: none;
`;

export const LoginModalButton = styled.button`
    padding: 10px 14px;
    height:50px;
    border-radius: 4px;
    border: none;
    background: #f15a25;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    width: 80%;
    margin:0 auto;
    margin-bottom: 50px;
    left:0;
    right:0;
`;

export const ModalTextSpan = styled.span`
    text-align: right;
    font-size: 16px;
    color: #fff;
    text-decoration: underline;
`;


export const BtnLink = styled(LinkRouter)`
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    margin-bottom: 0.5rem;

    &:hover{
        color: #000;
        transition: 0.3s ease-out;
    }
`;
