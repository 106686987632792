
const PostListReducer = (state = { allPostsList: undefined, loading: undefined, error: undefined }, action) => {
    switch (action.type) {
        case 'FETCHING_ALL_POSTS_LIST_PROCESS':
            return { ...state, isLoading: true };

        case 'FETCHING_ALL_POSTS_LIST_SUCCESS':
            return { ...state, allPostsList: action.payload, loading: false };

        case 'FETCHING_ALL_POSTS_LIST_ERROR':
            return { ...state, loading: false, error: action.payload };
            
        default:
            return state;
    }
};

export default PostListReducer;
