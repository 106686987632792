import { combineReducers } from 'redux';
import CategoriesReducer from 'redux/reducers/categoriesReducer';
import PostImagesListReducer from './postImagesReducer';
import PostListReducer from './postListReducer';

let rootReducer = combineReducers({
    categories: CategoriesReducer,
    postLists: PostListReducer,
    postImages: PostImagesListReducer
});

export default rootReducer;
