import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import searchContext from 'components/Context';
import { postList } from 'components/App';
import React, { useContext, useState } from 'react';

function RadioBox({ prices, handleFilters }) {
    const [value, setValue] = useState('');
    const [data, setData] = useContext(searchContext);
    const { categories, category, search, results, searched } = data;

    const handleChange = (event,item) => {
        handleFilters(event.target.value);
        setValue(event.target.value);
        handleClick(item);
    } 

    const handleClick = (item)=>{
        let postData = postList.allPostsList && postList.allPostsList.rows;
        if(item.array.length >0){
            let num1 = item.array[0];
            let num2 = item.array[1];
            
            if(postData){
                let searchDt = postData.filter((x) =>(
                x.expectedPrice >=num1 && x.expectedPrice <= num2
            ));
          setData({ ...data, results: searchDt, searched: true });
        }
        } else if(postData){ 
            setData({ ...data, results: postData, searched: true });
        } 
    }

    return (
        prices.map((item, index)=>{
            return <div key={index}>
                <FormControl>
                    <RadioGroup row name={item} value={value} onChange={(e)=>handleChange(e,item)} >
                        {
                            <FormControlLabel value={item.name} control={<Radio />} label={item.name} />
                        }
                    </RadioGroup>
                </FormControl>
            </div>
        })
        )
}

export default RadioBox;
