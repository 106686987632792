import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';

export const Navs = styled.nav`
    height: 70px;
    background:'#fff';
    display:flex;
    justify-content: center;
    align-items:center;
    font-size: 1rem;
    position: sticky; 
    top: 0;
    z-index: 100;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    } 
`;

export const Nav = styled.nav`
    height: 70px;
    background:${({ scrollNav }) => (scrollNav ? '#000' : '#000')};
    display:flex;
    justify-content: center;
    align-items:center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 100;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    } 
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 60px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`;

export const NavLogo = styled(LinkRouter)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    margin-left: 0px;
    font-weight: bold;
    text-decoration: none;
`;

export const NavLogos = styled(LinkRouter)`
    color: #000;
    position: relative;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    margin-left: 0px;
    font-weight: bold;
    text-decoration: none;
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #000;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 22px;

    @media screen and (max-width:768px){
        display: none;
        margin-right: 12px;
    }
`;

export const NavItem = styled.li`
    height: 70px;
`;

export const NavLinkIcon = styled(LinkScroll)`
    
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    cursor: pointer;
    height: 100%;
    font-weight: 700;
    margin-left: 22px;

    &.active{
        border-bottom: 3px solid #f15a25;
    }

    @media screen and (max-width: 768px) {
    margin-left: 12px;
    }
`;

export const NavLinks = styled(LinkScroll)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    cursor: pointer;
    height: 100%;
    font-weight: 700;
    margin-left: 22px;

    &.active{
        border-bottom: 3px solid #f15a25;
    }

    @media screen and (max-width: 768px) {
    margin-left: 12px;
    }
`;


export const NavLinkRouter = styled(LinkRouter)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    cursor: pointer;
    height: 100%;
    font-weight: 700;
    margin-left: 22px;

    &.active{
        border-bottom: 3px solid #f15a25;
    }

    @media screen and (max-width: 768px) {
    margin-left: 12px;
    }
`;


export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        display:none;
    }
`;

export const NavBtnLink = styled(LinkRouter)`
    border-radius: 15px;
    //margin-top: -70px;
    background: #f15a25;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    text-decoration: none;
    border: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-left: 22px;

    &:hover{
        transition: all 0.2s ease-in-out;
        color: #010606;
        background:#fff;
    }

    @media screen and (max-width: 768px) {
        margin-left: 10px;
    }
`;


export const NavSearchInput = styled.div`
    display: flex;
    width: 350px;
    height: 40px;
    align-items: center;

    & input {
        width: 100%;
        outline: none;
        height: 40px;
        padding: 0 0 0 15px;
        border-radius: 10px 0 0 10px;
    }
    & button {
        color: black;
        background-color: #f15a25;
        border: 0;
        border-radius: 0 10px 10px 0;
        width: 15%;
        height: 40px;
        outline: none;
        cursor: pointer;
    }
`;