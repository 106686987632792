import { createStore, applyMiddleware, compose } from 'redux';
import { persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import 'regenerator-runtime/runtime';
import rootSaga from 'redux/sagas/';
import rootReducer from 'redux/reducers';
import storage from 'redux-persist-indexeddb-storage';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: 'root',
    storage: storage('myDB'),
};

let store = createStore(persistReducer(persistConfig, rootReducer), storeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

export default store;
