import React, { useContext } from 'react'
import { Avatar, MenuItem, Paper,makeStyles, withStyles,ListItemIcon,ClickAwayListener} from '@material-ui/core'
import { composeContext } from 'components/App';
import { signout } from 'utils/Service';
import { IoMdLogOut } from 'react-icons/io';
import { useHistory} from "react-router-dom";
import {LineStyle} from "@material-ui/icons";
import { isAuthenticated } from 'utils/Service';
//import ClickAwayListener from '@mui/material'

const useStyles = makeStyles(theme=>({
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
}));

const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

function NavUserDropdown({setClose,handleClickAway}) {
    const history = useHistory();
    const classes = useStyles();
    const [messages, setMessages] = useContext(composeContext);
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper>
            <MenuItem >
            <Avatar  src={`data:${isAuthenticated().userAccount ? isAuthenticated().userAccount.imageType : ''};base64, ${isAuthenticated().userAccount ? isAuthenticated().userAccount.imageData: ''}`}
             className={classes.large} style= {{marginRight: 5}}/>
             {isAuthenticated().userAccount?isAuthenticated().userAccount.userRoleName: ''}
            </MenuItem>

            <StyledMenuItem onClick={isAuthenticated() && isAuthenticated().userAccount.userRoleName !== "Admin"
                ? () => history.push("/user")
                : () => history.push("/dashboard")
            }>
              <ListItemIcon><LineStyle className="sidebarIcon" /> </ListItemIcon> Dashboard</StyledMenuItem>

            <StyledMenuItem onClick={()=>{
                setMessages([]);
                signout(() => history.push("/"));
                setClose()
            }}> <ListItemIcon> <IoMdLogOut size="18px" color="inherit" /> </ListItemIcon>
            Sign Out</StyledMenuItem>
        </Paper>
      </ClickAwayListener>
    )
}

export default NavUserDropdown
