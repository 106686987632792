import React from 'react';

const SideMenuContex = React.createContext();

export function SideMenuProvider({ children }) {
    const [isOpen, setIsOpen] = React.useState(false);
    return <SideMenuContex.Provider value={{ isOpen, setIsOpen }}>{children}</SideMenuContex.Provider>;
}

export default SideMenuContex;
