import React from 'react';
import { SideMenuProvider } from 'utils/context';
import Sidebar from 'components/main/Sidebar/index';
import Navbar from 'components/main/Navbar/index';

export default function HeaderSection() {
    return (
        <>
            <SideMenuProvider>
                <Sidebar />
                <Navbar />
            </SideMenuProvider>
        </>
    ); 
}
