import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { categoryList } from 'components/App';

function CheckBox({ mainCategories, handleFilters, categorydetails }) {
    let catArray = new Array(categoryList.categories&&categoryList.categories.length).fill(false)
    const [checked, setChecked] = useState(catArray);
    //console.log(catArray)

    const handleToggle = (e) => () => {
        console.log(e.target.name); 
        setChecked(e.target); 
    };

    return (categoryList.categories ? categoryList.categories.map((cat, index) => {
        
        return (
            <FormControl  fullWidth key={index}>
                <FormControlLabel onChange={()=>{
                    if(checked[index]){
                        let updatedCheckedState = checked.map((item, i) =>
                        i === index ? false : item
                        );
                        setChecked(updatedCheckedState);
                    } else {
                        let updatedCheckedState = checked.map((item, i) =>
                        i === index ? true : false
                        );
                        setChecked(updatedCheckedState);
                    }
                }} control={
                    <Link
                        to={{
                            pathname: `/category/${cat && cat.category_name.replace(/\W+/g, '-').toLowerCase()}`,
                            state: {
                                data: cat
                            } 
                        }}>
                        <Checkbox  checked={checked[index]} name= {cat.category_name}
                            //onChange={}
                            value={cat.category_name} /></Link>
                } label={cat.category_name || cat.subCategoryName} />

            </FormControl>
        )
    }) : null);
}

export default CheckBox;
