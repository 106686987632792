import styled from 'styled-components';
const FallBack = styled.div`
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & .circle {
        border-radius: 50%;
        background-color: #ffffff;
        width: 150px;
        height: 150px;
        opacity: 0;
        position: absolute;
        animation: scaleIn 3s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
        @keyframes scaleIn {
            from {
                transform: scale(0.5, 0.5);
                opacity: 0.5;
            }
            to {
                transform: scale(2.5, 2.5);
                opacity: 0;
            }
        }
    }

    & .item {
        z-index: 100;
        padding: 5px;
    }

    & .item img {
        width: 150px;
        border-radius: 50%;
    }
`;

export default FallBack;
