import React from 'react';
import './newcard.css';
import TimeAgo from "react-timeago";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { RWebShare } from "react-web-share";
import IconButton from "@mui/material/IconButton";

function NewCard({
    featured,imgsrc,postCategory,postDetail,postTitle,price,
    time,data
}) {
  return (
    <div class="product-card">
        {featured&&<div class="badge">Featured</div>}
        <div class="product-tumb">
            <img src={imgsrc} alt="loading"/>
        </div>
        <div class="product-details">
            <span class="product-catagory">{postCategory}</span>
            <h4><a href="">{postTitle}</a></h4>
            <p>{postDetail}</p>
            <div class="product-bottom-details">
                <div class="product-price">{price}</div>
                <div class="product-links">
                    
                    <RWebShare 
                    data={data}
                    onClick={() => console.info("share successful!")}
                    >
                    <IconButton aria-label="$100">
                        <ShareIcon />
                    </IconButton>
                    </RWebShare>
                </div>
            </div>
            <p><TimeAgo date={time} /></p>
        </div>
    </div>
  )
}

export default NewCard