import FallBack from 'styled-components/fallback';
import Logo from 'assets/logo.png';

const FallBackLoader = () => (
    <FallBack>
        <div class='item'>
            <img src={Logo} />
        </div>
        <div class='circle'></div>
        <div class='circle'></div>
        <div class='circle'></div>
        <div class='circle'></div>
        <div class='circle'></div>
        <div class='circle'></div>
    </FallBack>
);

export default FallBackLoader;
