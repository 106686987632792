
export const prices = [
    {
        id: 0,
        name: 'any',
        array: []
    },
    {
        id: 1,
        name: '$0 to $99',
        array: [0, 99]
    },
    {
        id: 2,
        name: '$100 to $199',
        array: [100, 199]
    },
    {
        id: 3,
        name: '$200 to $299',
        array: [200, 299]
    },
    {
        id: 4,
        name: '$300 to $399',
        array: [300, 399]
    },
    {
        id: 5,
        name: '$400 to $499',
        array: [400, 499]
    },
    {
        id: 6,
        name: '$500 to $999',
        array: [500, 999]
    },
    {
        id: 7,
        name: '$1000 to $9999',
        array: [1000, 9999]
    },
    {
        id: 8,
        name: 'More than $10000',
        array: [10000, 999999]
    }
]