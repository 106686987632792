import React from "react";
import Footer from "../components/main/Footer/index";
import "../components/main/Signup/login.css";
import HeaderSection from "../components/main/Header/index";
import CategorySection from "../components/main/CategorySection/FrequentlyAsked";
import ScrollToTop from "components/ScrollToTop";

const FrequentlyAskedPage = () => {
  return (
    <>
      <ScrollToTop/>
      <HeaderSection />
      <CategorySection />
      <Footer />
    </>
  );
};

export default FrequentlyAskedPage;