import React, { useState, useEffect, useContext } from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtnLink,
  NavSearchInput,
  NavLinkRouter,
} from "./NavbarElements";
import logo from "../../../assets/logo.png";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import LoginModal from "../LoginModal";
import SideMenuContex from "../../../utils/context";
import { isAuthenticated } from "utils/Service";
import { useHistory, Redirect, Link } from "react-router-dom";
import { Popper } from "@material-ui/core";
import NavUserDropdown from "./NavUserDropdown";
import { ArrowDropDown } from "@material-ui/icons";
import searchContext from "components/Context";

const Navbar = () => {
  const history = useHistory();
  const { setIsOpen } = useContext(SideMenuContex);
  const [scrollNav, setScrollNav] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [data, setData] = useContext(searchContext)
    const { categories, category, search, results, searched } = data;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  const changeNav = () => {
    if (window.scrollY >= 70) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const toggle = () => {
    setIsOpen((prevState) => {
      return !prevState;
    });
  }


  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
    setData({ category: '', search: '', results: [], searched: false })
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav} >
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <img src={logo} width="45px" height="45px" alt="logo" />
              Trio Marketplace
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              {isAuthenticated() && isAuthenticated().userAccount ? (
                <>
                  <NavItem>
                    <NavLinks onClick={handleClick}>
                      <span style={{ color: "white", marginLeft: "5px" }}>
                        Hi, {isAuthenticated().userAccount.userName}
                      </span>
                      <ArrowDropDown />
                    </NavLinks>
                  </NavItem>
                </>
              ) : (
                <>
                  <NavItem>
                    <NavLinkRouter onClick={toggleHome} to="/" exact="true">
                      Home
                    </NavLinkRouter>
                  </NavItem>
                  <NavItem>
                    <NavLinks
                      onClick={openModal}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                    >
                      Login
                    </NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinkRouter to="/register" exact="true">
                      Register
                    </NavLinkRouter>
                  </NavItem>
                </>
              )}
              <NavBtnLink to="/user/postad" exact="true">
                Post an Ad
              </NavBtnLink>
              <LoginModal showModal={showModal} setShowModal={setShowModal} />
            </NavMenu>
          </NavbarContainer>
          <Popper open={open} anchorEl={anchorEl}>
            <NavUserDropdown handleClickAway={()=>setAnchorEl(null)} 
            setClose= {()=>{
              setAnchorEl(null)
            }} />
          </Popper>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
