
const CategoriesReducer = (state = { categories: undefined, loading: undefined, error: undefined }, action) => {
    switch (action.type) {
        case 'FETCHING_CATEGORIES_PROCESS':
            return { ...state, isLoading: true };

        case 'FETCHING_CATEGORIES_SUCCESS':
            return { ...state, categories: action.payload, loading: true };

        case 'FETCHING_CATEGORIES_ERROR':
            return { ...state, loading: false, error: action.payload };
            
        default:
            return state;
    }
};

export default CategoriesReducer;
