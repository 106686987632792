import { makeStyles, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        top: theme.spacing(9)
    }
}));

function Notification(props) {
    const classes = useStyles();
    const { notify, setNotify } = props;
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setNotify({
            ...notify,
            isOpen: false
        })
    }

    const [onlyWidth, setOnlyWidth] = React.useState();

    React.useEffect(() => {
      setOnlyWidth(window.outerWidth);
    }, []); 
    return (<>
        {onlyWidth >= 515 ? (
            <Snackbar
            className={classes.root}
            open={notify.isOpen}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={handleClose}
        >
            <Alert
                severity={notify.type}
                onClose={handleClose}
            >
                {notify.message}
            </Alert>
        </Snackbar>
        ) : (
            <Snackbar
            className={classes.root}
            open={notify.isOpen}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
        >
            <Alert
                severity={notify.type}
                onClose={handleClose}
            >
                {notify.message}
            </Alert>
        </Snackbar>
        )}
</>
    )
}

export default Notification;
