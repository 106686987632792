import styled from 'styled-components';

export const ListingContainer = styled.div`
    display:flex ;
    flex-direction: row ;
    justify-content:space-between ;
    align-items:flex-start;
    flex-wrap:wrap;
    width: 100% ;
    height: 100% auto ;
`;

export const ListContainer = styled.div`
    display:flex ;
    flex-direction: column ;
    justify-content:space-between ;
    align-items:flex-start;
    flex-wrap:wrap;
    width: 100% ;
    height: 100% auto ;
`;