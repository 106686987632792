import { Grid } from '@material-ui/core'
import Login from 'components/auth/Login'
import searchContext from 'components/Context'
import { CategoryContainer, CategoryWrapper } from 'components/main/CategorySection/CategorySectionElements'
import React, { useContext } from 'react'
import usePagination from '../Pagination';
import ResultCard from './ResultCard';
import Pagination from '@mui/material/Pagination';
import { makeStyles } from "@mui/styles";
import { postList } from "components/App";
import CircularUnderLoad from "components/CircularProgress";
import Breadcrumbs from 'components/Breadcrumbs'
import { ListingContainer } from './ListingStyle/listStyle'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: "2rem",
    },
}));

function SearchResults() {
    const [data, setData] = useContext(searchContext)
    const { results, searched, loading } = data;

    const [page, setPage] = React.useState(1);
    

    const PER_PAGE = 15;

    const count = results && Math.ceil(results.length / PER_PAGE);
    const _DATA = results && usePagination(results, PER_PAGE);

    const handlePageChange = (event, value) => {
        setPage(value);
        _DATA.jump(value);
    };

    const searchMessage = (searched, results) => {
        if (searched && results) {
            return `Found ${results.length} Ad(s)`;
        } else {
            return `No Ads found`;
        }
    }
    const onClick = () => {
        setData({ category: '', search: '', results: [], searched: false })
    }
    const classes = useStyles();
    

    return (<>{!results?<div className="w3-threequarter" style={{ paddingTop: "1rem" }}><CircularUnderLoad/></div>
            :<div className="w3-threequarter" style={{ paddingTop: "1rem" }}>
            <Breadcrumbs />
            <h2>{searchMessage(searched, results)}</h2>
            <ListingContainer>
            {_DATA.currentData().map((f, index) => (<ResultCard onClick={onClick} key={index} f={f} />))}
            </ListingContainer>
        <Grid alignItems={'center'}>
            <Pagination
                style={{
                    display: 'flex',
                    position: 'relative',
                    width: 'auto',
                    flexWrap: 'wrap'
                }}
                size='small'
                siblingCount={0}
                boundaryCount={1}
                count={count}
                page={page}
                onChange={handlePageChange}
            />
        </Grid>

    </div>}</>
    )
}

export default SearchResults

