import React from "react";
import SideMenuContex from "../../../utils/context";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarBtnWrap,
  SidebarRoute,
} from "./SidebarElements";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  MenuItem,
  Paper,
  makeStyles,
  withStyles,
  ListItemIcon,
} from "@material-ui/core";
import { composeContext } from "components/App";
import { signout } from "utils/Service";
import { IoMdLogOut } from "react-icons/io";
import { LineStyle } from "@material-ui/icons";
import { isAuthenticated } from "utils/Service";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const Sidebar = () => {
  const history = useHistory();
  const { isOpen, setIsOpen } = React.useContext(SideMenuContex);
  const [isAuth, setAuth] = React.useState(false);
  const { setMessages } = React.useContext(composeContext);

  const toggle = () => {
    setIsOpen((prevState) => {
      return !prevState;
    });
  };

  const classes = useStyles();
  React.useEffect(() => {
    isAuthenticated().userAccount ? setAuth(true) : setAuth(false);
  }, []);

  return (
    <SidebarContainer isOpen={isOpen}>
      <Icon onClick={() => toggle()}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        {isAuth ? (
          <>
            <SidebarMenu>
              <SidebarLink>
                <Avatar
                  src={`data:${isAuthenticated().userAccount
                      ? isAuthenticated().userAccount.imageType
                      : ""
                    };base64, ${isAuthenticated().userAccount
                      ? isAuthenticated().userAccount.imageData
                      : ""
                    }`}
                  className={classes.large}
                  style={{ marginRight: 5 }}
                />
                {isAuthenticated().userAccount
                  ? isAuthenticated().userAccount.userRoleName
                  : ""}
              </SidebarLink>
              <SidebarLink
                onClick={
                  isAuthenticated() &&
                    isAuthenticated().userAccount.userRoleName !== "Admin"
                    ? () => history.push("/user")
                    : () => history.push("/dashboard")
                }
              >
                Dashboard
              </SidebarLink>
              <SidebarLink
                onClick={() => {
                  signout(() => history.push("/"));
                }}
              >
                Sign Out
              </SidebarLink>
            </SidebarMenu>
            <SidebarBtnWrap>
              <SidebarRoute to="/user/postad">Post an Ad</SidebarRoute>
            </SidebarBtnWrap>
          </>
        ) : (

          <>
            <SidebarMenu>
              <SidebarLink to="/user/postad">
                Sell
              </SidebarLink>
              <SidebarLink to="/user/postad">
                Buy
              </SidebarLink>
              <SidebarLink to="/" >
                Discover
              </SidebarLink>
              <SidebarLink to="/register">Register</SidebarLink>
            </SidebarMenu>
            <SidebarBtnWrap>
              <SidebarRoute to="/signin">My Account</SidebarRoute>
            </SidebarBtnWrap>
          </>
        )}
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
