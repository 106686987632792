import { put, takeLatest, all, call } from 'redux-saga/effects';
import api from '../../utils/Api';


function* fetchAllCategories() {
    try {
        yield put({ type: 'FETCHING_CATEGORIES_PROCESS' });
        let res = yield call(api.getParentCategoryWithSubCategoryList, '');
        let { success, data, error } = res.data;
        if (success) {
            yield put({ type: 'FETCHING_CATEGORIES_SUCCESS', payload: data });
        } else {
            yield put({ type: 'FETCHING_CATEGORIES_ERROR', payload: `Error while fetching categories ${error}` });
        }
    } catch (error) {
        console.log(error)
    }
}

function* getAllPostsList() {
    try {
        yield put({ type: 'FETCHING_ALL_POSTS_LIST_PROCESS' });
        let res = yield call(api.getPostList, '');
        let { success, data, error } = res.data;
        if (success) {
            yield put({ type: 'FETCHING_ALL_POSTS_LIST_SUCCESS', payload: data });
        } else {
            yield put({ type: 'FETCHING_ALL_POSTS_LISTS_ERROR', payload: `Error while fetching all posts list ${error}` });
        }
    } catch (error) {
        console.log(error)
    }
}

function* getAllPostImagesList() {
    try {
        yield put({ type: 'FETCHING_ALL_POSTS_IMAGES_LIST_PROCESS' });
        let res = yield call(api.getPostImagesLists, '');
        let { success, data, error } = res.data;
        if (success) {
            yield put({ type: 'FETCHING_ALL_POSTS_IMAGES_LIST_SUCCESS', payload: data });
        } else {
            yield put({ type: 'FETCHING_ALL_POSTS_LISTS_IMAGES_ERROR', payload: `Error while fetching all posts images list ${error}` });
        }
    } catch (error) {
        console.log(error)
    }
}

function* actionWatcher() {
    yield takeLatest('GET_ALL_CATEGORIES', fetchAllCategories);
    yield takeLatest('GET_ALL_POSTS', getAllPostsList);
    yield takeLatest('GET_ALL_POSTS_IMAGES', getAllPostImagesList)
}

export default function* rootSaga() {
    yield all([actionWatcher()]);
}
