import React from 'react'

function FAQ() {
  return (
    <div className="w3-row w3-padding-16">
        <div className="w3-container">
            <article>
              <h1>Trio Marketplace Frequently Asked Questions & Most updated Users Guide</h1>
              <article>
                <h3 style={{color: 'blue'}}>What is Trio Marketplace?</h3>
                <p>
                Trio Marketplace Corporation (TMP) is an Online Classified Advertisement
                website with a sole purpose of bringing a fresh look at the current industry
                standards. Trio Marketplace Corporation is a federal incorporation registered under
                the Canada Business Corporations Act (CBCA) with its headquarters in Toronto,
                Ontario. Trio marketplace is a reliable website to buy, sell, browse, rent, lease: -
                Automobiles, Vehicles, Jobs, Services, Construction, Farming Equipment, Real
                Estate solutions, Aviation Freelancing, B2B, Garage sales, Softwares, Space,
                Astronomy, Travel, Insurance, electronics among others. Users are free to post
                new, used, items they have for sale to other community members. Trio
                Marketplace is a pending Trademark and we're glad to be a vehicle that unites
                communities at the same time making a difference in the lives of many individuals,
                businesses, and institutions. Don't forget to check us out on all our Social Media
                Platforms
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Why was Trio Marketplace created?</h3>
                <p>
                The Idea of Trio Marketplace Corporation (TMP) was founded in 2018 and three
                years later the company was created. The concept of Trio Marketplace is meant to
                give a more unique user experience and blending with future trends
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>How can I create an Account with Trio Marketplace?</h3>
                <dl>
                  <p>
                  Creating an Ad with Trio Marketplace is completely free. Please follow the
                  instructions given below:
                  </p>
                  <dd>Go to a search engine of your choice</dd>
                  <dd>Type in: - Trio Marketplace</dd>
                  <dd>On our website, click “Register” on the top tabs of the website</dd>
                  <dd>Fill out the form with your information (Name & Email)</dd>
                  <dd>Now you're logged in. You can post an Ad and also browse other items</dd>
                </dl>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>How can I post an Ad?</h3>
                <dl>
                  <p>
                  Posting an Ad with Trio Marketplace is completely free. Please follow the
                  instructions given below:
                  </p>
                  <dd>Go to a search engine of your choice</dd>
                  <dd>Type in: - Trio Marketplace</dd>
                  <dd>On our website, click “Register” on the top tabs of the website</dd>
                  <dd>Fill out the form with your information (Name & Email)</dd>
                  <dd>Now you're logged in. You can post an Ad and also browse other items</dd>
                </dl>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>What is the “set a search alert” Feature?</h3>
                <p>
                This feature is meant to notify the user when someone has posted an item in a
                category of their choice. This notification will be by email
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Do I need an account to browse items at Trio Marketplace?</h3>
                <p>
                You can browse any category you want without creating an account. In other
                words, anyone without an account can browse through our website
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Why does Trio Marketplace use Cookies?</h3>
                <p>
                We recommend that our users enable cookies for the following reasons:
                </p>
                <dl>
                  <dd>To improve user experience</dd>
                  <dd>Cookies make online shopping easier</dd>
                  <dd>Helps identify you from visitors</dd>
                  <dd>Check out our Cookie policy for more information</dd>
                </dl>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Can I Advertise on Trio Marketplace?</h3>
                <p>
                if you want to reach more eyeballs to your offer (Website / landing page), you will
                need to contact our Media Ads Department and your Ad will be Advertised for a
                fee to a much bigger audience
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Why was my Ad removed from the site?</h3>
                <p>
                There are several reasons why an Ad may have been removed from the site
                including:
                </p>
                <dl>
                  <dd>The Ad violated our community guidelines</dd>
                  <dd>Users flagged the Ad as being spam or offensive.</dd>
                  <dd>The Ad was posted with inappropriate language.</dd>
                  <dd>The Ad expired.</dd>
                  <dd>
                  Trio Marketplace reserves the right to remove an Ad that maybe deemed
                  inappropriate
                  </dd>
                </dl>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Can I post my Ad in multiple cities where I don't reside?</h3>
                <p>
                We prefer that your Ad appears in your location so that people can easily meet you
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>How many Ads can I post everyday?</h3>
                <p>You can post as many Ads as you want in a day</p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>How do I eliminate cookies?</h3>
                <dl>
                  <dd>Clear all cookies</dd>
                  <dd>On your Android phone or tablet, open the Chrome app.</dd>
                  <dd>To the right of the address bar, tap More. Settings.</dd>
                  <dd>Tap Privacy. Clear browsing data.</dd>
                  <dd>Choose a time range, like Last hour or All time.</dd>
                  <dd>Check "Cookies, media licenses and site data." Uncheck all the other items.</dd>
                  <dd>Tap Clear data. Clear.</dd>
                </dl>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>How can I avoid scammers?</h3>
                <dl>
                  <dd>Try to do all transactions during daytime.</dd>
                  <dd>Ask Questions but do not assume anything.</dd>
                  <dd>
                  Do not pay (etransfer, moneygram, western union, online banking) for anything
                  prior to meet up.
                  </dd>
                  <dd>Good sellers do not try to put a false sense of urgency</dd>
                  <dd>Have someone to witness a transaction</dd>
                </dl>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Why have I not received a confirmation email?</h3>
                <p>Check your Spam folder or inbox. You will see a verification email</p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>How can I contact the Seller of an Ad?</h3>
                <dl>
                  <dd>Login at Trio Marketplace, Fill out your profile</dd>
                  <dd>
                  Click on Trio Marketplace logo and finally click on any main category or
                  subcategories
                  </dd>
                  <dd>After you fill the Seller's Ad, beside it is a message box. Type the message and</dd>
                </dl>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>How do I suggest a new feature?</h3>
                <p>
                Trio Marketplace is constantly adding new features based on feedback from our
                community. Please use the contact us form to provide us your feedback about
                adding the new features at our site or technical issues incurred.
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Can I change the location of my ad?</h3>
                <p>Location can be updated.</p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>How can I increase the chance of getting a good response to my Ad?</h3>
                <p>
                It is better to have your Ad to run for many days such that you can get more traffic
                to your offer (Website / landing page),
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>What should I do when I can't find a fitting category for my ad?</h3>
                <dl>
                  <dd>Click on the main category</dd>
                  <dd>Look at the end of the subcategories inside the main category</dd>
                  <dd>You will see “other” as a subcategory</dd>
                  <dd>Anything that is not mentioned in the subcategories can be posted here</dd>
                </dl>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>How can I contact the poster of an Ad?</h3>
                <p>
                Go to the actual Ad you're interested in. Right beside it is a message box. Send the
                seller a message. The location is right below the message box
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>How can I increase the chance of getting a good response to my Ad?</h3>
                <p>
                To increase the chance of getting a good response, follow these simple and
                recommended tips:
                </p>
                <dl>
                  <dd>You should many pictures in your Ad.</dd>
                  <dd>Create a persuasive title, create a very detailed Ad description</dd>
                  <dd>Add a location</dd>
                  <dd>Add a suitable price</dd>
                  <dd>Check your emails including spam just in case</dd>
                </dl>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Are Ads screened before they go on the site?</h3>
                <p>
                Trio marketplace reviews Ads instantly but also continues to review them when
                they go live
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Can I also offer my service in non-service categories?</h3>
                <p>
                We prefer to find the appropriate category. If you cannot find it post in “other”
                which is at the end of every subcategory.
                </p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Can I post multiple ads?</h3>
                <p>Yes, can post unlimited number of Ads</p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>What should I do if I see an Ad that I think is spam, offensive?</h3>
                <dl>
                  <dd>In the Ad description click Spam.</dd>
                  <dd>
                  If you continuously report falsely, Trio Marketplace reserves the right to suspend
                  your account
                  </dd>
                </dl>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Do I need to registration to post an Ad?</h3>
                <p>yes, you need to create an account before you post an Ad</p>
              </article>

              <article>
                <h3 style={{color: 'blue'}}>Do you offer any type of conciliation between buyers & sellers?</h3>
                <p>
                We believe that users need to do their due diligence to locate the right people to
                buy or sell a product or service. Our role is to create a conducive environment or
                platform for people to do business in an easy and efficient way
                </p>
              </article>
            </article>
            <form id="Login" action="/">
                <button
                type="submit"
                style={{ padding: "16px", borderRadius: "15px" }}
                >
                BACK TO HOMEPAGE
                </button>
            </form>
        </div>
        <div className="w3-quarter">.</div>
    </div>
  )
}

export default FAQ