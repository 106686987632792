import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import { IconContext } from 'react-icons/lib';
import {
    LoginModalButton, LoginModalCloseButton, LoginModalContainer, LoginModalContent, LoginModalImg, LoginModalP, LoginModalWrapper,
    ModalFormContent, ModalFormInput, ModalFormLabel, ModalFormWrap, ModalForm, ModalTextSpan
} from './LoginModalElements';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { API } from "../../../config";
import Notification from 'components/form_controls/Notification';
import { authenticate, isAuthenticated } from '../../../utils/Service';
import logo from '../../../assets/logo.png'
import { redirectContext } from 'components/App';

const initialValues = {
    email: '',
    password: '',
    loading: false,
    redirectToRefferer: false
}


const LoginModal = ({ showModal, setShowModal, UserMessage, }) => {
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
    const [loginValues, setLoginValues] = useState(initialValues);
    const [redirectStatus, setRedirect] = useContext(redirectContext);
    const modalRef = useRef();

    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const closeModal = e => {
        if (modalRef.current === e.target) {
            setShowModal(false);
        }
    };

    const keyPress = useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(false);
                console.log('I pressed');
            }
        },
        [setShowModal, showModal]
    );

    useEffect(
        () => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );

    const handleUserMessage = async () => {
        if (UserMessage) {
            const msg = JSON.stringify(UserMessage);
            let response = await axios.post(`/api/messaging/send`, msg, { headers: { Accept: "application/json", "Content-Type": "application/json" } })
            if (response.data.success) {
                setRedirect(true)
            }
        }
    }

    const handleInputChange = e => {
        setLoginValues({
            ...loginValues,
            [e.target.name]: e.target.value
        });
    }

    const clickSubmit = async (e) => {
        e.preventDefault();
        setLoginValues({ ...loginValues, loading: true });
        const data = JSON.stringify(loginValues);
        let res = await axios.post(`/api/users/auth/signin`, data, { headers: { Accept: "application/json", "Content-Type": "application/json" } })
        if (res.data.token) {
            handleUserMessage();
            authenticate(res.data, () => {
                setLoginValues({ ...loginValues, redirectToRefferer: true });
                setNotify({
                    isOpen: true,
                    message: "Logged In Successfully!",
                    type: "success"
                });
                setShowModal(false);
            });
        } else {
            setLoginValues({ ...loginValues, loading: false });
            setNotify({
                isOpen: true,
                message: `${res.data.error}`,
                type: "error"
            });
        }
    }

    const redirectUser = () => {
        if (loginValues.redirectToRefferer && !redirectStatus) {
            if (isAuthenticated() && isAuthenticated().userAccount.userRoleName !== "Admin") {
                return <Redirect to="/user" />
            } else {
                return <Redirect to="/dashboard" />
            }
        } if (redirectStatus) {
            return <Redirect to='/user/messaging' />
        }
    }

    return (
        <>
            {showModal && (
                <IconContext.Provider value={{ color: 'black', size: '50px' }}>
                    <LoginModalContainer onClick={closeModal} ref={modalRef}>
                        <animated.div style={animation}>
                            <LoginModalWrapper showModal={showModal}>
                                <LoginModalImg src={logo} alt="image" />
                                <LoginModalContent>
                                    <h1>Login Here!</h1>
                                    <LoginModalP>Sigin to your account</LoginModalP>
                                    <ModalFormWrap>
                                        <ModalFormContent>
                                            <ModalForm>
                                                <ModalFormLabel htmlFor="for">Email Address</ModalFormLabel>
                                                <ModalFormInput onChange={handleInputChange} name="email" value={loginValues.email} type="email" required />
                                                <ModalFormLabel htmlFor="for">Password</ModalFormLabel>
                                                <ModalFormInput onChange={handleInputChange} name="password" value={loginValues.password} type="password" required />
                                                <ModalTextSpan><Link to="/forgotpassword">Forgot Password?</Link></ModalTextSpan>
                                                <LoginModalButton onClick={clickSubmit}>
                                                    Continue
                                                </LoginModalButton>

                                            </ModalForm>
                                            <span>New here? <Link to="/register" style={{ color: 'blue' }}>Register</Link></span>
                                        </ModalFormContent>
                                    </ModalFormWrap>
                                </LoginModalContent>
                                <LoginModalCloseButton aria-label="Close modal" onClick={() => setShowModal(prev => !prev)
                                } />
                            </LoginModalWrapper>
                        </animated.div>
                    </LoginModalContainer>
                </IconContext.Provider>
            )}
            {redirectUser()}
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    )
};

export default LoginModal;
