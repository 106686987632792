
const PostImagesListReducer = (state = { allPostsImageList: undefined, loading: undefined, error: undefined }, action) => {
    switch (action.type) {
        case 'FETCHING_ALL_POSTS_IMAGES_LIST_PROCESS':
            return { ...state, isLoading: true };

        case 'FETCHING_ALL_POSTS_IMAGES_LIST_SUCCESS':
            return { ...state, allPostsImageList: action.payload, loading: false };

        case 'FETCHING_ALL_POSTS_LISTS_IMAGES_ERROR':
            return { ...state, loading: false, error: action.payload };
            
        default:
            return state;
    }
};

export default PostImagesListReducer;
